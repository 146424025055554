import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Layout from "../components/layout";

const EarningsDisclaimer = () => {
  return (
    <Layout title={`Earnings Disclaimer`}>
      <Container>
        <Row>
          <Col className="mt-5">
            <h1>Earnings Disclaimer</h1>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-5">
            <p>
              BusinessPro.co may earn a small commission if you purchase a
              product or service through the links on our site. We use these
              commissions to fund the operation of BusinessPro.co by paying our
              developers, content creators, website managers, and hosting costs.
            </p>
            <p>
              BusinessPro.co is a participant in the following affiliate
              networks, including, but not limited to CJ, Rakuten LinkShare,
              Impact Radius, ShareASale and eBay Enterprise network.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default EarningsDisclaimer;
